import { NextPage } from 'next'
import Link from 'next/link'
import { Routes } from 'routes'

const FourOhFour: NextPage = () => (
  <>
    <h1>404 - Page Not Found</h1>
    <Link href={Routes.MyAccount}>
      <a>Go back home</a>
    </Link>
  </>
)

export default FourOhFour
